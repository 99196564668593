export type SellingPlanAnchorType = 'WEEKDAY' | 'MONTHDAY' | 'YEARDAY'
export type SellingPlanPreAnchorBehavior = 'ASAP' | 'NEXT'

export interface SellingPlanAnchor {
  day: number
  month: number | null
  type: SellingPlanAnchorType
}

export const anchorWeekDayNames = [
  null,
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]
export const anchorMonthNames = [
  null,
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const anchorShortMonthNames = [
  null,
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]
