import {
  Banner,
  Button,
  Error,
  ExceptionList,
  FormLayout,
  HorizontalStack,
  LegacyStack,
  Modal,
  SelectOption,
  Text,
} from '@shopify/polaris'
import { InfoMinor } from '@shopify/polaris-icons'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  anchorMonthNames,
  anchorWeekDayNames,
  SellingPlanAnchor,
  SellingPlanAnchorType,
  SellingPlanPreAnchorBehavior,
} from './anchor-models'
import { DateInputWithSelector } from './DateInputWithSelector'
import {
  DialogActivator,
  formatOrdinal,
  MemoedSelect,
  mergeArrays,
  pluralize,
  range,
  useShopFlag,
} from './helpers'
import { HelpLabel } from './HelpIcon'
import {
  MemoedNumericSelect,
  MemoedOptionalNumericSelect,
  NumericSelectOption,
  OptionalNumericSelectOption,
} from './NumericSelect'
import { SellingPlanInterval } from './plan-models'
import { PrepaidScopesNeededModal } from './PrepaidScopeNeededModal'
import { AnchorTypeField } from './ScheduleFields'

const intervalAnchorTypes: Record<string, SellingPlanAnchorType> = {
  [SellingPlanInterval.WEEK]: 'WEEKDAY',
  [SellingPlanInterval.MONTH]: 'MONTHDAY',
  [SellingPlanInterval.YEAR]: 'YEARDAY',
}

export const anchorTypeSellingPlanIntervals: Record<SellingPlanAnchorType, SellingPlanInterval> = {
  WEEKDAY: SellingPlanInterval.WEEK,
  MONTHDAY: SellingPlanInterval.MONTH,
  YEARDAY: SellingPlanInterval.YEAR,
}

const weekDayOptions: OptionalNumericSelectOption[] = range(1, 7).map((i) => ({
  label: anchorWeekDayNames[i]!,
  value: i,
}))

const monthDayOptions = range(1, 31).map((i) => ({
  label: `${formatOrdinal(i)} of the month`,
  value: i,
})) as OptionalNumericSelectOption[]

const yearDayOptions = range(1, 31).map((i) => ({
  label: formatOrdinal(i),
  value: i,
})) as OptionalNumericSelectOption[]

const dayOptions: Record<string, OptionalNumericSelectOption[]> = {
  [SellingPlanInterval.WEEK]: weekDayOptions,
  [SellingPlanInterval.MONTH]: monthDayOptions,
  [SellingPlanInterval.YEAR]: yearDayOptions,
}

const monthOptions: NumericSelectOption[] = range(1, 12).map((i) => ({
  label: anchorMonthNames[i]!,
  value: i,
}))

export const anchorDayLabel = (anchorType: SellingPlanAnchorType) =>
  anchorType === 'YEARDAY' ? 'Anchor date' : 'Anchor day'

interface AnchorFieldProps {
  anchor: SellingPlanAnchorInputData | null
  interval: SellingPlanInterval
  error?: Error
  onChange: (anchor: SellingPlanAnchorInputData) => any
}

export const AnchorField = ({ anchor, interval, error, onChange }: AnchorFieldProps) => {
  const newAnchorType = intervalAnchorTypes[interval]
  const newAnchor = {
    day: anchor?.day ?? 1,
    month: anchor?.month ?? (newAnchorType === 'YEARDAY' ? 1 : null),
    type: newAnchorType,
  }

  return (
    <LegacyStack distribution="fillEvenly">
      {anchor?.type === 'YEARDAY' && (
        <DateInputWithSelector
          label={anchorDayLabel(newAnchorType)}
          labelHidden={true}
          day={anchor?.day ?? 1}
          month={anchor?.month ?? 1}
          onUpdate={(day?: number, month?: number) => {
            onChange({ ...newAnchor, day, month })
          }}
          error={error}
        />
      )}
      {anchor?.type !== 'YEARDAY' && (
        <>
          <MemoedOptionalNumericSelect
            label={anchorDayLabel(newAnchorType)}
            labelHidden={true}
            options={dayOptions[interval]}
            onChange={(day: number) => onChange({ ...newAnchor, day })}
            value={anchor?.day ?? null}
            error={error}
          />
          {interval === SellingPlanInterval.YEAR && (
            <MemoedNumericSelect
              label="Month"
              labelHidden={true}
              options={monthOptions}
              onChange={(month: number) => onChange({ ...newAnchor, month })}
              disabled={!anchor}
              value={anchor?.month ?? 1}
              error={error}
            />
          )}
        </>
      )}
    </LegacyStack>
  )
}

export const cutoffOptions = mergeArrays(
  [{ label: 'Disabled', value: null } as OptionalNumericSelectOption],
  range(1, 365).map((i) => ({ value: i, label: `${i} ${pluralize(i, 'day')}` }))
)

export const preAnchorBehaviorLabels: Record<SellingPlanPreAnchorBehavior, string> = {
  ASAP: 'On checkout',
  NEXT: 'On anchor',
}

export const preAnchorBehaviorOptions: SelectOption[] = [
  { label: preAnchorBehaviorLabels.ASAP, value: 'ASAP' },
  { label: preAnchorBehaviorLabels.NEXT, value: 'NEXT' },
]

export const firstDeliveryTooltip = (
  <>
    <b>On checkout</b> &mdash; schedules the first delivery immediately after checkout. If the
    customer orders within the cutoff window at checkout, the first delivery is scheduled for the
    anchor date, and the second delivery is set for the next anchor date.
    <br />
    <b>On anchor</b> &mdash; schedules the first delivery on the nearest anchor day.
  </>
)

export const cutoffWindowTooltip = (
  <>
    Skips the first delivery if it's too close to the nearst anchor day.
    <br />
    <br />
    When <b>First delivery</b> is <b>On checkout</b>, it'll prevent two deliveries from being
    scheduled too close to each other.
    <br />
    <br />
    When <b>First delivery</b> is <b>On anchor</b>, it'll jump over to the subsequent anchor day, to
    give you enough time to prepare the order.
  </>
)

export interface SellingPlanAnchorInputData {
  day?: number
  month?: number | null
  type: SellingPlanAnchorType
}

export const AnchorsEditModal = (props: {
  activator: DialogActivator
  anchors: SellingPlanAnchor[]
  interval: SellingPlanInterval
  preAnchorBehavior?: SellingPlanPreAnchorBehavior
  cutoff?: number | null
  onChange: (
    anchors: SellingPlanAnchor[],
    preAnchorBehavior?: SellingPlanPreAnchorBehavior,
    cutoff?: number | null
  ) => any
  pagePath: string
}) => {
  const hasFulfillmentOrderScopes = useShopFlag('has_fulfillment_order_scopes')

  const [interval, setInterval] = useState(props.interval)

  const anchorType = intervalAnchorTypes[interval]
  const newAnchor = useCallback(
    (anchorType: SellingPlanAnchorType) =>
      ({
        day: 1,
        month: anchorType === 'YEARDAY' ? 1 : null,
        type: anchorType,
      } as SellingPlanAnchorInputData),
    []
  )

  const initialAnchors = useMemo(
    () =>
      props.anchors.length > 0
        ? (props.anchors as SellingPlanAnchorInputData[])
        : [newAnchor(intervalAnchorTypes[props.interval])],
    [props.anchors, newAnchor, props.interval]
  )

  const [anchors, setAnchors] = useState(initialAnchors)
  const [preAnchorBehavior, setPreAnchorBehavior] = useState(props.preAnchorBehavior)
  const [cutoff, setCutoff] = useState(props.cutoff)
  const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    setInterval(props.interval)
    setAnchors(initialAnchors)
    setPreAnchorBehavior(props.preAnchorBehavior)
    setCutoff(props.cutoff)
    setHasErrors(false)
  }, [props.activator, initialAnchors, props.preAnchorBehavior, props.cutoff, props.interval])

  const handleAnchorChange = (anchor: SellingPlanAnchorInputData, i: number) => {
    const newAnchors = [...anchors]
    newAnchors[i] = anchor
    setAnchors(newAnchors)
  }

  const handleAnchorRemove = (i: number) => {
    const newAnchors = [...anchors]
    newAnchors.splice(i, 1)
    setAnchors(newAnchors)
  }

  const validateAnchor = useCallback(
    (anchor: SellingPlanAnchorInputData, i: number) => {
      if (anchor.day === undefined) {
        return 'Date must be in MM-DD format'
      }

      return anchors.findIndex(
        (a, j) => j < i && a.day === anchor.day && a.month === anchor.month
      ) === -1
        ? undefined
        : 'Duplicate anchor'
    },
    [anchors]
  )

  const isValidForm = useCallback(() => {
    let res = true
    anchors.forEach((anchor, i) => {
      if (validateAnchor(anchor, i) !== undefined) {
        res = false
        return
      }
    })
    return res
  }, [anchors, validateAnchor])

  const onSubmit = () => {
    if (!isValidForm()) {
      setHasErrors(true)
      return
    }
    props.onChange(anchors as SellingPlanAnchor[], preAnchorBehavior, cutoff)
    props.activator.close()
  }

  if (!hasFulfillmentOrderScopes) {
    return (
      <PrepaidScopesNeededModal activator={props.activator} pagePath={props.pagePath}>
        <p>To start using anchors, you need to accept additional permissions in Shopify</p>
      </PrepaidScopesNeededModal>
    )
  }

  return (
    <Modal
      open={props.activator.open}
      title="Edit delivery anchors"
      onClose={props.activator.close}
      primaryAction={{
        content: 'Done',
        onAction: onSubmit,
      }}
      secondaryActions={[{ content: 'Cancel', onAction: props.activator.close }]}
      sectioned
      small
    >
      <FormLayout>
        {hasErrors && (
          <Banner status="critical">
            <Text as="p">Please fix the errors before submitting the form</Text>
          </Banner>
        )}
        <AnchorTypeField
          label="Anchor type"
          onChange={(val: SellingPlanInterval) => {
            setInterval(val)
            setAnchors([newAnchor(intervalAnchorTypes[val])])
          }}
          value={interval}
        />

        <ExceptionList
          items={[
            {
              status: 'warning',
              icon: InfoMinor,
              description: 'Deliveries will repeat on these dates annually',
            },
          ]}
        />
        <LegacyStack vertical spacing="tight">
          {anchors.length > 0 && (
            <HelpLabel tooltip="Schedule deliveries for specified day">
              {anchorDayLabel(anchorType)}
            </HelpLabel>
          )}
          {anchors.map((anchor, i) => (
            <LegacyStack.Item fill>
              <HorizontalStack blockAlign="baseline" gap="2">
                <LegacyStack.Item fill>
                  <AnchorField
                    key={i}
                    anchor={anchor}
                    onChange={(anchor) => handleAnchorChange(anchor, i)}
                    interval={interval}
                    error={validateAnchor(anchor, i)}
                  />
                </LegacyStack.Item>
                <Button onClick={() => handleAnchorRemove(i)} destructive>
                  Remove
                </Button>
              </HorizontalStack>
            </LegacyStack.Item>
          ))}
        </LegacyStack>
        <HorizontalStack align="end">
          <Button
            onClick={() => setAnchors([...anchors, newAnchor(intervalAnchorTypes[interval])])}
            plain
          >
            Add delivery anchor
          </Button>
        </HorizontalStack>
        {preAnchorBehavior && (
          <MemoedSelect
            label={<HelpLabel tooltip={firstDeliveryTooltip}>First Delivery</HelpLabel>}
            options={preAnchorBehaviorOptions}
            onChange={(selected) => setPreAnchorBehavior(selected as SellingPlanPreAnchorBehavior)}
            value={preAnchorBehavior}
            disabled={anchors.length === 0}
          />
        )}
        {cutoff !== undefined && (
          <MemoedOptionalNumericSelect
            label={<HelpLabel tooltip={cutoffWindowTooltip}>Cutoff window</HelpLabel>}
            options={cutoffOptions}
            onChange={setCutoff}
            value={cutoff}
            disabled={anchors.length === 0}
          />
        )}
      </FormLayout>
    </Modal>
  )
}
